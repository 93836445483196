import React from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import { graphql, useStaticQuery } from "gatsby"
import {useTranslation} from "react-i18next"
import GymSticker from "../../components/App/GymSticker/GymSticker";

const FaqPartner = ({ location }) => {

  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query appPageFaqPartnersLtEn {
      site {
        siteMetadata {
          countries {
            lt {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.lt.hotLine}
      location={location}
      country="lt_en"
    >
      <Head
        lang="lt_en"
        title={t('head.faq_partner.title')}
        slug="/faq_partner/"
        apps={true}
      />
      <section className="main-page">
        <div>
          <h1>Instructions for partners</h1>
          <h2>Installing the program. </h2>

          <p>
            To install a working application for Sportbenefit partners, you must:
          </p>

          <ul>
            <li>
              Through Google Chrome, follow the link{" "}
              <a href="https://supply.sportbenefit.eu/supplier/login ">
                https://supply.sportbenefit.eu/supplier/login
              </a>{" "}
              , a login form will appear on the main screen, which you need to
              make an individual{" "}
              <strong>login and password provided by the company's manager </strong>
              Sportbenefit LLC;
            </li>
          </ul>

          <ul>
            <li>Confirm password saving and allow access to the camera;</li>
          </ul>

          <ul>
            <li>
              For ease of use - you should select in the additional
              settings <strong>"add to home screen"</strong>. After
              After that, the application{" "}
              <strong>"Supplier Panel"</strong> will appear on the phone's home screen and repeat the login and password entry.
            </li>
          </ul>

          <p>
            <strong>
              In the case of an iPhone phone - login via Siri and the app is added
              to Bookmarks
            </strong>
          </p>

          <h2>How to receive a visitor with the Sportbenefit app?</h2>

          <GymSticker language="lt_en" />

          <h2>How do I receive a visitor with an Sportbenefit card?</h2>

          <p>
            The holder of the Sportbenefit multisport card has the right to access
            partner clubs <strong>1 time per day.</strong> If the limit has already been reached,
            after scanning the card, the application will notify you about this with the messages{""}
            <strong>"NO ACCESS" </strong>and {" "}
            <strong>"the daily limit has been reached</strong>"
          </p>

          <p>
            <strong>The Sportbenefit card is named</strong> and allows you to
            use only the one whose data is indicated on the card.
          </p>

          <ul>
            <li>
              Running the program automatically opens the map scan panel
              (QR code on the back).
            </li>
          </ul>

          <ul>
            <li>
              With a successful scan, the table {" "}
              <strong>"cardholder data"</strong> opens and the option to select
              reject-confirm
            </li>
          </ul>

          <ul>
            <li>
              Administrator{" "}
              <strong>
                is obliged to verify the card data with the visitor's identity card
              </strong>{" "}
              (passport, driver's license, residence permit). In the case
              data discrepancies - the administrator has the right to withdraw the card and
              must enter the card data (in the table "cardholder data, field
              located at the bottom)
            </li>
          </ul>

          <ul>
            <li>
              <strong>Confirm</strong> - means that the Holder's data matches
              cards and the beginning of the service. <strong>Reject</strong> - data
              the cards did not match or the client refused to visit before the start
              providing the service. If your gym provides
              several types of services or an additional payment is required at the ticket office of the hall, will appear
              drop-down windows for selecting services.{" "}
              <strong>
                The completion of the visitor registration ends with the opening of a new one
                scan windows (cameras)
              </strong>
            </li>
          </ul>

          <h2>Navigation</h2>

          <ul>
            <li>
              <strong>New registration</strong>. Opens the application in the mode
              scanning a new card.
            </li>
          </ul>

          <ul>
            <li>
              <strong>Visits</strong>. The visits tab displays all
              scanning of Sportbenefit cards, with the date and time, as well as
              indications of the services provided and their quantity. Also have
              the ability to select the desired month with a filter.
            </li>
          </ul>

          <ul>
            <li>
              <strong>About us.</strong> The tab contains phone contacts
              manuals and a link to the user manual.
            </li>
          </ul>

          <ul>
            <li>
              <strong>About you.</strong> The tab contains information about your
              the club that we provide to Sportbenefit Cardholders. Also
              there is a list of services and conditions that your
              club provides for the Sportbenefit company (i.e. what services can be provided
              to a visitor with an Sportbenefit card)
            </li>
          </ul>

          <h2>Have a nice job!</h2>
        </div>
      </section>
    </Layout>
  )
}

export default FaqPartner
